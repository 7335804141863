var env_data = {};(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

require('./scripts/dropdown.js');

require('./scripts/menu.js');

require('./scripts/modals.js');

require('./scripts/scrolltop.js');

require('./scripts/tabs.js');

require('./scripts/ui.js');

require('./scripts/show-more.js');

require('./scripts/select.js');

},{"./scripts/dropdown.js":2,"./scripts/menu.js":3,"./scripts/modals.js":4,"./scripts/scrolltop.js":5,"./scripts/select.js":6,"./scripts/show-more.js":7,"./scripts/tabs.js":8,"./scripts/ui.js":9}],2:[function(require,module,exports){
"use strict";

// This script is meant only to make the markup more interactive
// Please do not use on production unless you think is perfectly fits your needs
$.fn.dropdown = function (cfg) {
  var dropdown = function dropdown($el) {
    var cfg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var handler = cfg.handler;
    $el.on('click', '.dropdown-button', function () {
      $el.hasClass('active') ? $el.removeClass('active') : $el.addClass('active');
    }).on('click', '.dropdown-item', function () {
      !$(this).hasClass('active') ? $(this).addClass('active').siblings().removeClass('active') : false;
      $el.removeClass('active');

      if (handler) {
        handler($el, $(this));
      }
    });
    $(document).mouseup(function (e) {
      !$el.is(e.target) && $el.has(e.target).length === 0 ? $el.removeClass('active') : false;
    });
  };

  for (var i = 0; i < this.length; i++) {
    dropdown($(this[i]), cfg);
  }
};

$('[dropdown]').dropdown();
$('[dropdown-pricing-plan]').dropdown({
  handler: function handler($el, $dropdownItem) {
    var $input = $el.find('[dropdown-input]');
    var $priceBox = $('[price-box]');
    var $usesBox = $('[uses-box]');
    var $titleBox = $('[title-box]');
    var value = $dropdownItem.data('value');
    var price = $dropdownItem.data('price');
    var uses = $dropdownItem.data('uses');
    var title = $dropdownItem.data('title');
    $input.val(value);
    $usesBox.text(uses);
    $titleBox.text(title);
    $priceBox.html(price);
  }
});

},{}],3:[function(require,module,exports){
"use strict";

// This script is meant only to make the markup more interactive
// Please do not use on production unless you think is perfectly fits your needs
$.fn.menu = function () {
  var menu = function menu($el) {
    $el.on('click', '.burger', function (e) {
      e.preventDefault();
      $el.hasClass('open-menu') ? $el.removeClass('open-menu') : $el.addClass('open-menu');
    });
    $(document).mouseup(function (e) {
      !$el.is(e.target) && $el.has(e.target).length === 0 ? $el.removeClass('open-menu') : false;
    });
  };

  for (var i = 0; i < this.length; i++) {
    menu($(this[i]));
  }
};

$('.block-header').menu();

},{}],4:[function(require,module,exports){
"use strict";

// This script is meant only to make the markup more interactive
// Please do not use on production unless you think is perfectly fits your needs
$.fn.modal = function () {
  var modal = function modal($el) {
    var $close = $el.find('.modal-close, .modal-backdrop');
    $('body').on('click', '.modal-button', function (e) {
      var data = $(this).attr('data-modal');
      e.preventDefault();
      $('body').find('#' + data).addClass('active').siblings('.modal').removeClass('active');
    });
    $el.on('click', '.modal-close, .modal-backdrop', function (e) {
      e.preventDefault();
      $(this).closest('.modal').removeClass('active');
    });
  };

  for (var i = 0; i < this.length; i++) {
    modal($(this[i]));
  }
};

$('.is-modal').modal();

},{}],5:[function(require,module,exports){
"use strict";

// This script is meant only to make the markup more interactive
// Please do not use on production unless you think is perfectly fits your needs
$.fn.scrolltop = function () {
  var scrolltop = function scrolltop($el) {
    $el.on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  };

  for (var i = 0; i < this.length; i++) {
    scrolltop($(this[i]));
  }
};

$('[scrollTop]').scrolltop();

},{}],6:[function(require,module,exports){
"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

$.prototype.select = function () {
  var _this = this;

  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var select = function select($el, params) {
    var selectClasses = $el.attr('class');
    var elDataType = $el.data('type');
    var elDataName = $el.data('name');
    var optItem = $el.find('option');
    var placeholder = $el.attr('data-placeholder');
    var multiple = $el.attr('multiple');
    var options = []; //construction

    var selWrapper = $('<div class="select ' + selectClasses + '" mgsWrapper />');
    var selHead = $('<div class="select-head" mgsHead />');
    var selPlaceholder = $('<div class="select-placeholder" mgsPlacegolder>' + placeholder + '</div>');
    var selSelected = $('<div class="select-selected" mgsselected />').hide();
    var selBody = $('<div class="select-body" mgsBody />');
    var selOptions = $('<ul class="select-options" mgsOptions />');
    var selOption = selOptions.find('.select-option'); // multiple items

    var optBox = $('<div class="select-options-box" />');
    var optBoxItem = optBox.find('.selected-option-item');
    var optBoxLength = optBox.children().length; //optional construction

    var selSearch = $('<div class="select-search" mgsSearch />');
    var selSearchInput = $('<input class="search-input" type="text" placeholder="">'); //parametrs

    var height = params.height || "auto";
    var search = params.search || !!$el.attr('data-search');
    var searchPlaceholder = params.searchPlaceholder || !!$el.attr('data-search-placeholder'); // input parametrs

    var withInput = $el.hasClass('with-input');
    var removeInput;

    _this.selBodyHeight = function () {
      if (!height) {
        selBody.css({
          'max-height': height,
          'overflow': 'hidden'
        });
      } else {
        selBody.css({
          'max-height': height,
          'overflow-y': 'scroll',
          'overflow-x': 'hidden'
        });
      }
    };

    _this.selSearch = function () {
      if (search !== true) {
        return false;
      }

      if ($el.attr('data-search-placeholder')) {
        selSearchInput.attr('placeholder', $el.attr('data-search-placeholder'));
      } else {
        selSearchInput.attr('placeholder', searchPlaceholder);
      }

      selSearch.append(selSearchInput);
      selHead.addClass('withSearch').append(selSearch);
      selSearchInput.on('change keyup keypress', function () {
        var inputVal = $(this).val();
        var field = selOptions.find('.select-option');
        var q = new RegExp(inputVal, 'ig');

        for (var i = 0, l = field.length; i < l; i += 1) {
          var $option = $(field[i]);

          if ($option.text().match(q)) {
            if ($option.hasClass('checked')) {
              $option.hide();
              selOptions.css('height', 'auto !important');
            } else {
              $option.show();
            }
          } else {
            $option.hide();
          }
        }

        ;
      });
    };

    _this.build = function ($el) {
      $el.hide().wrap(selWrapper);
      selWrapper = $el.parent();
      selHead.append(selPlaceholder);
      selHead.append(selSelected);
      selWrapper.append(selHead);
      selOptions.append(selOption);
      selBody.append(selOptions);
      selWrapper.append(selBody); //add data attr

      if (elDataType) {
        selHead.attr('data-type', elDataType);
      }

      if (elDataName) {
        selHead.attr('data-name', elDataName);
      }

      if (withInput) {
        selHead.append('<div class="count-input"><div class="input-placeholder">St. </div><input type="number"><div class="remove-input"><img src="assets/img/icons/remove-regular.svg"></div></div>');
      }
    };

    _this.array = function () {
      optItem.each(function () {
        var $index = $(this).index();
        var $text = $(this).text();
        var $class = $(this).attr('class');
        var $value = $(this).attr('value');
        var $selected = $(this).attr('selected');

        if ($selected) {
          options.push({
            index: $index,
            "class": $selected,
            text: $text,
            value: $value
          });
        } else {
          options.push({
            index: $index,
            "class": $class,
            text: $text,
            value: $value
          });
        }
      });

      for (var i = 0; i < options.length; i++) {
        if (options[i]["class"]) {
          selOptions.append($('<li data-index="' + options[i].index + '", class="select-option ' + options[i]["class"] + '" data-value="' + options[i].value + '" magic-select-link>' + options[i].text + '</li>'));
        } else {
          selOptions.append($('<li data-index="' + options[i].index + '", class="select-option" data-value="' + options[i].value + '" magic-select-link>' + options[i].text + '</li>'));
        }
      }

      return options;
    };

    _this.activity = function () {
      selHead.on('click', function () {
        if ($(this).hasClass('withSearch')) {
          $(this).addClass('focused');
          selSearchInput.focus();
        } else {
          if ($(this).hasClass('focused')) {
            $(this).removeClass('focused');
          } else {
            $(this).addClass('focused');
          }
        }
      });
      selOptions.on('click', '.select-option', function () {
        var self = $(this);
        var index = self.index();
        var $text = self.text();

        if (self.hasClass('input-button')) {
          selHead.addClass('input-visible').removeClass('focused selected');
          selHead.find('.count-input').children('input').focus();
          return false;
        } else {
          //console.log('this option turn input')
          selHead.removeClass('input-visible');

          if (search == true) {
            selSearchInput.val('');
            selOptions.find('.select-option').show();
          }

          if (!multiple) {
            if (self.hasClass('selected')) {
              self.removeClass('selected');
              selSelected.text('').hide().siblings(selPlaceholder).show();
              selHead.removeClass('focused selected');
              optItem.eq(index).attr('selected', false);
            } else {
              self.addClass('selected').siblings().removeClass('selected');
              selSelected.text($text).show().siblings(selPlaceholder).hide();
              selHead.removeClass('focused').addClass('selected');
              optItem.eq(index).attr('selected', 'selected').siblings().attr('selected', false);
            }
          } else {
            optBoxLength++;
            selWrapper.append(optBox);
            selSelected.text(optBoxLength + ' items selected').show().siblings(selPlaceholder).hide();
            self.hide();
            optBox.append($('<div class="selected-option-item" data-index="' + index + '">' + $text + '&nbsp;<div class="option-item-remove" optItmRmv ></div></div>'));
            optItem.eq(index).attr('selected', 'selected');
          }
        }

        $el.trigger('change');
      });
      optBox.on('click', '.option-item-remove', function () {
        var self = $(this);
        var index = self.parent().data('index');
        optBoxLength--;

        if (optBoxLength < 1) {
          selSelected.text('').hide().siblings(selPlaceholder).show();
        } else {
          selSelected.text(optBoxLength + ' items selected').show().siblings(selPlaceholder).hide();
        }

        self.parent().remove();
        optItem.eq(index).attr('selected', false);
        selOptions.find('[data-index="' + index + '"').show(); //$el.trigger('change');

        return false;
      });
      selHead.find('.remove-input').on('click', function () {
        $(this).siblings('input').val('');
        selHead.removeClass('input-visible');
      });
      $(document).mouseup(function (e) {
        if (!selWrapper.is(e.target) && selWrapper.has(e.target).length === 0) {
          selHead.removeClass('focused');

          if (search == true) {
            selSearchInput.val('');
            selOptions.find('.select-option').show();
          }
        }
      });
    };

    _this.forSelected = function () {
      optItem.each(function () {
        var attr = $(this).attr('selected');

        if (_typeof(attr) !== (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) && attr !== false) {
          var index = $(this).index();
          var text = selOptions.find('.select-option').eq(index).text();
          selOptions.find('.select-option').eq(index).addClass('selected');
          selPlaceholder.hide();
          selSelected.text(text).show();
          selHead.addClass('selected');
          optItem.eq(index).attr('selected', 'selected');

          if (multiple) {
            optBoxLength++;
            selWrapper.append(optBox);
            selSelected.text(optBoxLength + ' items selected').show().siblings(selSearch).text('').hide();
            optBox.append($('<div class="selected-option-item" data-index="' + index + '">' + text + '&nbsp;<div class="option-item-remove" optItmRmv ></div></div>'));
          }
        }
      });
    };

    _this.construct = function () {
      selPlaceholder.text($el.attr('data-placeholder'));
    };

    _this.init = function () {
      this.build($el);
      this.selSearch();
      this.array(options);
      this.selBodyHeight();
      this.forSelected();
      this.activity();
    };

    _this.init();
  };

  for (var i = 0; i < this.length; i++) {
    select($(this[i]), _objectSpread({}, params));
  }
};

$('body').find('[select]').select();

},{}],7:[function(require,module,exports){
"use strict";

$.prototype.showMore = function () {
  var showMore = function showMore($el) {
    var $link = $el.find('[link]');
    var $showLink = $el.find('[show-more-link]');
    var $hideLink = $el.find('[show-less-link]');
    var $shortText = $el.find('[show-more-short]');
    var $fullText = $el.find('[show-more-full]');
    $link.on('click', function (e) {
      e.preventDefault();
      $shortText.toggleClass('hidden');
      $fullText.toggleClass('hidden');
      $showLink.toggleClass('hidden');
      $hideLink.toggleClass('hidden');
    });
  };

  for (var i = 0; i < this.length; i++) {
    showMore($(this[i]));
  }
};

$.prototype.showHidden = function () {
  var showHidden = function showHidden($el) {
    var itemName = $el.attr('show-item');
    var event = $el.attr('show-action');
    var $hidenItem = itemName ? $('[hidden-item = ' + itemName + ']') : null;
    var $hiddenInput = $hidenItem.find('input');

    if ($hidenItem && event) {
      if (event == 'change') {
        if ($el.is(':checked')) {
          $hidenItem.removeClass('hidden');
        } else {
          $hidenItem.addClass('hidden');
        }
      }

      $el.on(event, function (e) {
        e.preventDefault();

        if (event == 'click') {
          if ($hidenItem.hasClass('hidden')) {
            $hidenItem.removeClass('hidden');

            if ($hiddenInput) {
              $hiddenInput.removeAttr('readonly');
            }
          } else {
            $hidenItem.addClass('hidden');

            if ($hiddenInput) {
              $hiddenInput.val('');
              $hiddenInput.prop('readonly', true);
            }
          }
        }

        if (event == 'change') {
          if ($el.is(':checked')) {
            $hidenItem.removeClass('hidden');

            if ($hiddenInput) {
              $hiddenInput.removeAttr('readonly');
            }
          } else {
            $hidenItem.addClass('hidden');

            if ($hiddenInput) {
              $hiddenInput.val('');
              $hiddenInput.prop('readonly', true);
            }
          }
        }
      });
    }
  };

  for (var i = 0; i < this.length; i++) {
    showHidden($(this[i]));
  }
};

$('[show-more-container]').showMore();
$('[btn-show-hidden]').showHidden();

},{}],8:[function(require,module,exports){
"use strict";

// This script is meant only to make the markup more interactive
// Please do not use on production unless you think is perfectly fits your needs
$.fn.tabs = function () {
  var tabs = function tabs($el) {
    $el.on('click', '.tab-link', function (e) {
      var data = $(this).attr('data-tab');
      e.preventDefault();
      $(this).addClass('active').siblings().removeClass('active');
      $('body').find('#' + data).addClass('active').siblings('.tab-wrapper').removeClass('active');
    });
  };

  for (var i = 0; i < this.length; i++) {
    tabs($(this[i]));
  }
};

$('[tabs]').tabs();

},{}],9:[function(require,module,exports){
"use strict";

// This script is meant only to make the markup more interactive
// Please do not use on production unless you think is perfectly fits your needs
$.fn.datePicker = function () {
  var datePicker = function datePicker($el) {
    var $input = $el.find('[dateInput]');
    var $wrapper = $el.find('[dateWrapper]');
    var dateFormat = $input.attr('date-format') || 'dd/mm/yy';
    var date = $input.val();
    $wrapper.datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      inline: true,
      defaultDate: date,
      altField: $input,
      dateFormat: dateFormat
    });
    $input.on('click', function (e) {
      e.preventDefault();
      $el.hasClass('active') ? $el.removeClass('active') : $el.addClass('active');
    }).on('change', function () {
      $wrapper.datepicker('setDate', $input.val());
    });
    $(document).mouseup(function (e) {
      !$el.is(e.target) && $el.has(e.target).length === 0 ? $el.removeClass('active') : false;
    });
  };

  for (var i = 0; i < this.length; i++) {
    datePicker($(this[i]));
  }
};

$('[datepicker]').datePicker();

},{}]},{},[1]);
